import React from "react";
import { PlayerNameContainer, PlayerStatsContainer, TrendsHomePage } from "./styles";

function Trends() {
    return (
        <TrendsHomePage>
        </TrendsHomePage>
    );
}

export default Trends;